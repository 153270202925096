"use client";
import Tooltip from "@clayton-homes/home-designer/components/tooltip";

export interface PinPointData {
	title: string;
	text: string;
	className: string;
}

export default function PinPoint({ pinPoint }: { pinPoint: PinPointData }) {
	return (
		<Tooltip>
			<Tooltip.Trigger
				className={`${pinPoint.className} absolute flex h-10 w-10 animate-pulse items-center justify-center rounded-full border border-white`}
			>
				<div className="h-6 w-6 rounded-full bg-white" />
			</Tooltip.Trigger>
			<Tooltip.Content
				style={{ "--hd-color-tooltip-primary": "white" }}
				className="w-64 border border-white p-4 text-black"
			>
				<h6 className="font-semibold uppercase text-primary-500">
					{pinPoint.title}
				</h6>
				{pinPoint.text}
			</Tooltip.Content>
		</Tooltip>
	);
}
