import(/* webpackMode: "eager" */ "/app/app/client.components.tsx");
;
import(/* webpackMode: "eager" */ "/app/assets/background-image-cta.webp");
;
import(/* webpackMode: "eager" */ "/app/assets/circles-on-dark-blue.webp");
;
import(/* webpackMode: "eager" */ "/app/assets/circles-on-light-blue.webp");
;
import(/* webpackMode: "eager" */ "/app/assets/customize-circles.webp");
;
import(/* webpackMode: "eager" */ "/app/assets/home-circles.webp");
;
import(/* webpackMode: "eager" */ "/app/assets/kitchen-with-stools.webp");
;
import(/* webpackMode: "eager" */ "/app/assets/specialist-circles.webp");
;
import(/* webpackMode: "eager" */ "/app/assets/svgs/checkmark.svg");
;
import(/* webpackMode: "eager" */ "/app/assets/svgs/design-home.svg");
;
import(/* webpackMode: "eager" */ "/app/assets/svgs/picture-chair-and-lamp.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/page-heading/client.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
